import React, { useContext } from "react";
import { useForm } from "../../hooks/form-hook";
import { useHistory } from "react-router-dom";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { TeacherContext } from "../../context/teacher-context";
import { TuteurContext } from "../../context/tuteur-context";

import { ContractorContext } from "../../context/contractor-context";
import { isRequired, isGemaEmail } from "../../utils/validators";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../components/Input/Input";
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Static Assets
import logo from "../../assets/images/logo-my-gema.png";

// Styles
import styles from "./AdminPage.module.scss";

const AdminEmailPage = () => {
    // Authentication context
    const auth = useContext(AuthContext);

    // Student App Context
    const studentAppContext = useContext(StudentContext);

    // Teacher App Context
    const teacherAppContext = useContext(TeacherContext);

    // Teacher App Context
    const tuteurAppContext = useContext(TuteurContext);

    // Contractor App Context
    const contractorAppContext = useContext(ContractorContext);

    // History context
    const history = useHistory();

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    // Input Hook
    const [formState, inputHandler] = useForm(
        {
            email: {
                value: "",
                isValid: false,
            },
        },
        false
    );

    const fetchContextHandler = async () => {
        if (!formState.isValid) return;

        // Fetch Variables
        const url = `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_AUTH_URL}/${process.env.REACT_APP_CONTEXT_URL}`;
        const email = formState.inputs.email.value;

        try {
            const context = await sendRequest(url, "POST", JSON.stringify({ email }), {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            });

            // Coment the line of code down below to test login situation cases in admin mode 
            // context.situation = "standard";
            
            // Pass data to the login Auth Hook
            auth.login(context.token, context.expires, context.role, context.type, context.situation, context.backToken);

            switch (context.type) {
                case "student":

                    // Pass data to the Student Context Hook
                    studentAppContext.setStudentContext(
                        context.status,
                        context.restricted,
                        context.campus,
                        context.accademicPeriod,
                        context.profile_photo,
                        context.name,
                        context.finStatus,
                        context.cvecIdentifier,
                        context.accademicLevel,
                        context.school,
                        context.attestation,
                        context.certificate,
                        context.nextYearLevel,
                        context.nextYearCampus,
                        context.rythme,
                        context.offresRestriction,
                        context.notificationOfferHome
                    );

                    if (context.situation === "justify-absences") history.push("/justify-absences");
                    else if (context.situation === "first-login") history.push("/contact-details");
                    else history.push("/");

                    break;

                case "teacher":

                    // Pass data to the Teacher Context Hook
                    teacherAppContext.setTeacherContext(
                        context.accounts,
                        context.mainAccount,
                        context.placeId,
                        context.mainCampus,
                        context.canInvoice,
                        context.isFreelancer,
                        context.vcardId,
                        context.currentAccountYear,
                        context.campuses,
                        context.multipleCampuses,
                        context.currentMultipleCampuses,
                        context.name,
                    );
                    break;

                    case "tuteur":

                        // Pass data to the Teacher Context Hook
                        tuteurAppContext.setTuteurContext(
                            context.status,
                            context.restricted,
                            context.campus,
                            context.accademicPeriod,
                            context.profile_photo,
                            context.name,
                            context.finStatus,
                            context.cvecIdentifier,
                            context.accademicLevel,
                            context.school,
                            context.attestation,
                            context.certificate,
                            context.nextYearLevel,
                            context.nextYearCampus,
                            context.rythme
                        );
                        break;

                case "contractor":
                    
                    // Pass data to the Contractor Context Hook
                    contractorAppContext.setContractorContext(
                        context.contractorSelectedId,
                        context.campus,
                        context.teachersIds,
                        context.teachers,
                        context.currentAccountYear,
                        context.contractorIds
                    );
                    history.push("/");
                    break;

                default:
                    break;
            }
            history.push("/");

        } catch (error) {
            console.error(error);
        }
    };

    const logout = () => {
        studentAppContext.unsetStudentContext();
        teacherAppContext.unsetTeacherContext();
        tuteurAppContext.unsetTuteurContext();
        contractorAppContext.unsetContractorContext();
        auth.logout();
    };

    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && (
                <div className={styles.wrapper}>
                    <form className={styles.container}>
                        <header className={styles.header}>
                            <div className={styles.logo}>
                                <img src={logo} className={styles.logo_img} alt="" />
                            </div>
                            <h1 className={styles.title}>Bienvenue</h1>
                            <p className={styles.text}>
                                Veuillez renseigner l'email concerné pour continuer
                            </p>
                            <div className={styles.input}>
                                <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    typeOfInput="input"
                                    placeholder="prenom.nom.type@groupe-gema.com"
                                    validators={[isRequired(), isGemaEmail()]}
                                    errorText="Le format de l'email n'est pas correct"
                                    initialValue={formState.inputs.email.value}
                                    initialValid={formState.inputs.email.isValid}
                                    onInput={inputHandler}
                                    keyPress={(e) => { if (e.key === "Enter") fetchContextHandler(e) }}
                                />
                            </div>
                            <ActionBtn
                                id="login_as_btn"
                                btnType="button"
                                btnStyle="contained"
                                btnColor="primary"
                                btnText="Vision Administrateur"
                                textStyle={styles.btn_text}
                                activeBtnStyle={styles.btn_active}
                                onClick={fetchContextHandler}
                            />
                            <ActionBtn
                                id="logout_btn"
                                btnType="button"
                                btnStyle="contained"
                                btnColor="primary"
                                btnText="Annuler la Demande"
                                textStyle={styles.btn_text_outlined}
                                activeBtnStyle={styles.btn_active_outlined}
                                onClick={logout}
                            />
                        </header>
                        <p className="error_message">{error}</p>
                    </form>
                </div>
            )}
        </>
    );
};

export default AdminEmailPage;
