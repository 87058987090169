import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { useForm } from "../../../../../hooks/form-hook";
import { isRequired } from "../../../../../utils/validators";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

// Components
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner"
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./ContactDetails.module.scss";

const ContactDetails = ({ student, setStudent, formJwt }) => {

    // Authentication context
    const auth = useContext(AuthContext);

    const history = useHistory();

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();

    // FormState Hook
    const [formState, inputHandler, multipleInputHandler] = useForm(
        {
            tel_cell: {
                value: student.tel_cell || "",
                isValid: student.tel_cell ? true : false,
            },
            email: {
                value: student.email || "",
                isValid: student.email ? true : false,
            },
            adr_street: {
                value: student.adr_street || "",
                isValid: student.adr_street ? true : false,
            },
            adr_zip: {
                value: student.adr_zip || "",
                isValid: student.adr_zip ? true : false,
            },
            adr_city: {
                value: student.adr_city || "",
                isValid: student.adr_city ? true : false,
            },
            adr_country: {
                value: student.adr_country || "",
                isValid: student.adr_country ? true : false,
            },
            contact_2_role: {
                value: student.contact_2_role || "",
                isValid: student.contact_2_role ? true : false,
            },
            contact_2_n_last: {
                value: student.contact_2_n_last || "",
                isValid: student.contact_2_n_last ? true : false,
            },
            contact_2_n_first: {
                value: student.contact_2_n_first || "",
                isValid: student.contact_2_n_first ? true : false,
            },
            contact_2_tel_work: {
                value: student.contact_2_tel_work || "",
                isValid: student.contact_2_tel_work ? true : false,
            },
        },
        false
    );

    const emergencyContact = [
        { label: "parent", default: "Parent" },
        { label: "frere_soeur", default: "Frère/Soeur" },
        { label: "ami", default: "Ami" },
        { label: "collegue", default: "Collègue" },
        { label: "conjoint", default: "Conjoint.e" },
        { label: "autre", default: "Autre" },
    ];

    const submit = async () => {
        const data = {
            adr_street: formState.inputs.adr_street.value,
            adr_zip: formState.inputs.adr_zip.value,
            adr_city: formState.inputs.adr_city.value,
            adr_country: formState.inputs.adr_country.value,
        };

        try {
            const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
            const response = await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                'X-CSRF-Token' : formJwt
            });

            setStudent((prevData) => {
                const { adr_street, adr_zip, adr_city, adr_country } = data;
                return { ...prevData, adr_street, adr_zip, adr_city, adr_country };
            });
        } catch (err) {
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    history.push("/profile");
                }, 3000)
            } else {
                toast.error("Un problème est survenu sur le serveur, veuillez réessayer ultérieurement", {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    const submitContact = async () => {
        const data = {
            n_last: formState.inputs.contact_2_n_last.value,
            n_first: formState.inputs.contact_2_n_first.value,
            tel_work: formState.inputs.contact_2_tel_work.value,
        };

        // Première action pour créer un VCARD OU update un VCARD existant.
        try {
            const url = `${process.env.REACT_APP_API_HOST}/profile/profile-vcard`;
            const response = await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                'X-CSRF-Token' : formJwt
            });
        } catch (err) {
            console.error(err);
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    history.push("/profile");
                }, 3000)
            } else {
                toast.error("Un problème est survenu sur le serveur, veuillez réessayer ultérieurement", {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000)
    }, [okHttp, error]);

    return (
        <>
            <ToastContainer />
            {isLoading && <InLineLoadingSpinner />}
            {okHttp && !isLoading && (
                <div className={styles.coordContainer}>
                    <p style={{ color: "#00b61d" }}>
                        Votre demande a bien été prise en compte. Merci.
                    </p>
                </div>
            )}
            <div className={styles.coordContainer}>
                <div className={styles.coordInput}>
                    <h6 className={styles.subtitle}>Coordonnées personnelles</h6>
                    <Input
                        onInput={inputHandler}
                        label="Numéro de téléphone"
                        type="text"
                        name="tel_cell"
                        id="tel_cell"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.tel_cell.value}
                        initialValid={formState.inputs.tel_cell.isValid}
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Email personnel"
                        type="text"
                        name="email"
                        id="email"
                        validators={[isRequired()]}
                        typeOfInput="input"
                        initialValue={formState.inputs.email.value}
                        initialValid={formState.inputs.email.isValid}
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Adresse *"
                        type="text"
                        name="adr_street"
                        id="adr_street"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        initialValue={formState.inputs.adr_street.value}
                        initialValid={formState.inputs.adr_street.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Code Postal *"
                        type="text"
                        name="adr_zip"
                        id="adr_zip"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        typeOfInput="input"
                        initialValue={formState.inputs.adr_zip.value}
                        initialValid={formState.inputs.adr_zip.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Ville *"
                        type="text"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        name="adr_city"
                        id="adr_city"
                        typeOfInput="input"
                        initialValue={formState.inputs.adr_city.value}
                        initialValid={formState.inputs.adr_city.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Pays *"
                        type="text"
                        name="adr_country"
                        id="adr_country"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        typeOfInput="input"
                        initialValue={formState.inputs.adr_country.value}
                        initialValid={formState.inputs.adr_country.isValid}
                        styles={styles.input}
                    />
                    <ActionBtn
                        id="subitCivilState"
                        btnType="contained"
                        btnStyle={styles.submitBtn}
                        activeBtnStyle={styles.btn_active}
                        btnText="ENREGISTRER"
                        textStyle={styles.btn_text}
                        onClick={submit}
                    />
                </div>
                <div className={styles.coordInput}>
                    <h6 className={styles.subtitle}>Contact en cas d'urgence</h6>
                    <Input
                        onInput={inputHandler}
                        label="Contact *"
                        type="text"
                        name="contact_2_role"
                        id="contact_2_role"
                        content={emergencyContact}
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        typeOfInput="select"
                        initialValue={formState.inputs.contact_2_role.value}
                        initialValid={formState.inputs.contact_2_role.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Nom *"
                        type="text"
                        name="contact_2_n_last"
                        id="contact_2_n_last"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        initialValue={formState.inputs.contact_2_n_last.value}
                        initialValid={formState.inputs.contact_2_n_last.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Prénom *"
                        type="text"
                        name="contact_2_n_first"
                        id="contact_2_n_first"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        initialValue={formState.inputs.contact_2_n_first.value}
                        initialValid={formState.inputs.contact_2_n_first.isValid}
                        styles={styles.input}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Numéro de téléphone *"
                        type="text"
                        name="contact_2_tel_work"
                        id="contact_2_tel_work"
                        validators={[isRequired()]}
                        errorText="Champ requis."
                        typeOfInput="input"
                        initialValue={formState.inputs.contact_2_tel_work.value}
                        initialValid={formState.inputs.contact_2_tel_work.isValid}
                        styles={styles.input}
                    />
                    <ActionBtn
                        id="subitCivilState"
                        btnType="contained"
                        btnStyle={styles.submitBtn}
                        activeBtnStyle={styles.btn_active}
                        btnText="ENREGISTRER"
                        textStyle={styles.btn_text}
                        onClick={submitContact}
                    />
                </div>
            </div>
        </>
    );
};

export default ContactDetails;
