/* eslint-disable eqeqeq */
import React, { useContext, useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { useForm } from "../../hooks/form-hook";
import { AuthContext } from "../../context/auth-context";
import { StudentContext } from "../../context/student-context";
import { ToastContainer, toast } from 'react-toastify';
import { MinLength, isRequired, isText, isAlphanumeric, isNumber, isCvec } from "../../utils/validators";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import ListHeader from "../../components/ListHeader/ListHeader";
import Input from "../../components/Input/Input";
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";

// Icons
import logOutIcon from "../../assets/images/leaveIcon.svg";

// Styles
import styles from "./ContactDetailsForm.module.scss";
import FilePicker from "../../components/FilePicker/FilePicker";
import 'react-toastify/dist/ReactToastify.css';

const ContactDetailsForm = () => {
    
    // Authentication context
    const auth = useContext(AuthContext);

    // Teacher App context
    const context = useContext(StudentContext);

    // History context
    const history = useHistory();

    //  State pour errorCountry
    const [errorCountryMessage, setErrorCountryMessage] = useState("");


    // Backend Request Hook
    const { isLoading, error, okHttp, sendRequest, resetHttpRequest } = useHttpRequest();

    const [formJwt, setFormJwt] = useState(false);

    // Input Hook
    const [formState, inputHandler] = useForm(
        {
            birth_date: { value: "", isValid: false },
            place_of_birth: { value: "", isValid: false },
            adr_street: { value: "", isValid: false },
            adr_zip: { value: "", isValid: false },
            adr_city: { value: "", isValid: false },
            adr_country: { value: "", isValid: false },
            cvec: { value: "", isValid: true },
            file: { value: "", isValid: false },
        },
        false
    );

    const fetchContextHandler = async () => {
        try {
            const url = `${process.env.REACT_APP_API_HOST}/login/reload-context`;
            const loginData = await sendRequest(url, "GET", null, {
                Authorization: "Bearer " + auth.token,
            });

            if (loginData.status === "blocked") {
                context.unsetStudentContext();
                auth.logout();
            }

            // Pass data to the login Auth Hook
            auth.login(
                loginData.token,
                loginData.expires,
                loginData.role,
                loginData.type,
                loginData.situation,
                loginData.backToken
            );

            // Pass data to student context Hook
            context.setStudentContext(
                loginData.status,
                loginData.restricted,
                loginData.campus,
                loginData.accademicPeriod,
                loginData.profile_photo,
                loginData.name,
                loginData.finStatus,
                loginData.accademicLevel,
                loginData.school,
                loginData.attestation,
                loginData.certificate,
                loginData.nextYearLevel,
                loginData.nextYearCampus,
                loginData.rythme,
                loginData.offresRestriction,
                loginData.notificationOfferHome
            );
            history.push("/");
        } catch (err) {
            console.error(err);
        }
    };

    const saveDataHandler = async () => {
        
        let formValidFalse = false;

        for (const [key, value] of Object.entries(formState.inputs)) { 
            if (formState.inputs[key].isValid == true) {
            } else {
                if ((key == 'file' && formState.inputs.cvec.value == '' && formState.inputs[key].value == '') ||
                (key == 'cvec' && formState.inputs[key].isValid == false)
                ) {
                    continue;
                } 
                formValidFalse = true;
            }
        }

        formState.isValid = formValidFalse ? false : true;

        if (!formState.isValid) {
            let focus;
    
            for (const [key, value] of Object.entries(formState.inputs)) {
                for (const [keyInput, valueInput] of Object.entries(value)) {
                    if (keyInput == 'isValid' && valueInput != true) {
                        if (key == 'cvec' || !document.getElementsByName(key)[0]) {
                            continue;
                        }
                        focus = document.getElementsByName(key)[0];
                    }
                }
            }
    
            let message;
            if (focus['name'] === 'file') message = 'Merci de bien vouloir charger votre attestation de CVEC afin de finaliser le formulaire.';
            else message = "Merci de compléter le champ manquant afin de finaliser le formulaire.";
        
            toast.error(`${message}`, {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        if (formState.inputs.adr_country.value !== "France" && formState.inputs.adr_country.value !== "france" && formState.inputs.adr_country.value !== "FRANCE") {
            setErrorCountryMessage("Merci de saisir votre lieu de résidence en France");
            return;
          }

        const formData = new FormData();
        formData.append("birth_date", formState.inputs.birth_date.value);
        formData.append("place_of_birth", formState.inputs.place_of_birth.value);
        formData.append("adr_street", formState.inputs.adr_street.value);
        formData.append("adr_zip", formState.inputs.adr_zip.value);
        formData.append("adr_city", formState.inputs.adr_city.value);
        formData.append("adr_country", formState.inputs.adr_country.value);
        if (formState.inputs.cvec.value != '') formData.append("cvec_n", formState.inputs.cvec.value);
        if (formState.inputs.file.value) {
            formData.append("file", formState.inputs.file.value);
            formData.append('year', context.studentCurrentPeriod);
        }

        try {
          const url = `${process.env.REACT_APP_API_HOST}/profile/update-contact`;
          const postContact = await sendRequest(url, "PUT", formData, {
            Authorization: "Bearer " + auth.token,
            "X-CSRF-Token": formJwt,
          });
        } catch (error) {
          console.error(error);
          if (error.message === "CSRF") {
            toast.error(
              "Votre formulaire a expiré. Votre page va être actualisée.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              }
            );
            setTimeout(() => {
              history.push("/");
              history.push("/profile");
            }, 3000);
          } else {
            toast.error(
              "Un problème est survenu sur le serveur, veuillez réessayer ultérieurement",
              {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
              }
            );
          }
        }
    };

    useEffect(() => {
        const getFormJwt = async () => {
            try {
                const url = `${process.env.REACT_APP_API_HOST}/profile/get-form-jwt`;
                const formJwtFromBackEnd = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setFormJwt(formJwtFromBackEnd);
                resetHttpRequest();
            } catch (err) {
                console.error(err)
            }
        }
        if (!formJwt) getFormJwt();
    }, [formJwt])

    return (
      <>
        {!isLoading && (
          <div className={styles.logoutWrapper}>
            <ActionBtn
              id="test"
              btnType="contained"
              activeBtnStyle={styles.logout_btn}
              btnText="Me déconnecter"
              icon={logOutIcon}
              iconColor={styles.whiteIcon}
              textStyle={styles.btn_text}
              onClick={() => {
                context.unsetStudentContext();
                auth.logout();
              }}
            />
          </div>
        )}
        <main className="tab_container">
          <ToastContainer />
          {isLoading && (
            <div className="spinner">
              <LoadingSpinner />
            </div>
          )}
          {error && (
            <div className="error_wrapper">
              <HttpMessagePrompt error={error} />
            </div>
          )}
          {!isLoading && okHttp && !error && (
            <article className={styles.container}>
              <HttpMessagePrompt
                error={false}
                message="Vos coordonnées ont été bien enregistrées."
                secondaryMessage="Vous pouvez continuer votre navigation en cliquant le bouton ci-dessous."
                btn={
                  <ActionBtn
                    id="closeBtn"
                    btnType="contained"
                    activeBtnStyle={styles.btn_active}
                    btnText="Continuer"
                    textStyle={styles.btn_text}
                    onClick={fetchContextHandler}
                  />
                }
              />
            </article>
          )}
          {!isLoading && !okHttp && !error && (
            <article className={styles.container}>
              <ListHeader
                title="Vos Coordonnées"
                message="Veuillez renseigner l'information ci-dessous"
              />
              <form className={styles.form}>
                <Input
                  id="birth_date"
                  name="birth_date"
                  type="date"
                  typeOfInput="input"
                  pattern="\d{4}-\d{2}-\d{2}"
                  label="Date de naissance *"
                  placeholder="jj/mm/aaaa"
                  validators={[isRequired()]}
                  errorText="Champ requis."
                  initialValue={formState.inputs.birth_date.value}
                  initialValid={formState.inputs.birth_date.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="place_of_birth"
                  name="place_of_birth"
                  type="text"
                  typeOfInput="input"
                  label="Lieu de naissance *"
                  placeholder="Nantes"
                  validators={[MinLength(3), isText()]}
                  errorText="Texte uniquement"
                  initialValue={formState.inputs.place_of_birth.value}
                  initialValid={formState.inputs.place_of_birth.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="adr_street"
                  name="adr_street"
                  type="text"
                  typeOfInput="input"
                  label="Adresse *"
                  placeholder="1 Rue de France"
                  validators={[MinLength(10), isAlphanumeric()]}
                  errorText="Texte alphanumérique uniquement"
                  initialValue={formState.inputs.adr_street.value}
                  initialValid={formState.inputs.adr_street.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="adr_zip"
                  name="adr_zip"
                  type="text"
                  typeOfInput="input"
                  label="Code Postal *"
                  placeholder="75000"
                  validators={[MinLength(5), isNumber()]}
                  errorText="Chiffres sans espaces uniquement. (Min 5 chiffres)"
                  initialValue={formState.inputs.adr_zip.value}
                  initialValid={formState.inputs.adr_zip.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="adr_city"
                  name="adr_city"
                  type="text"
                  typeOfInput="input"
                  label="Ville *"
                  placeholder="Paris"
                  validators={[MinLength(3), isText()]}
                  errorText="Texte uniquement"
                  initialValue={formState.inputs.adr_city.value}
                  initialValid={formState.inputs.adr_city.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="adr_country"
                  name="adr_country"
                  type="text"
                  typeOfInput="input"
                  label="Pays *"
                  placeholder="France"
                  validators={[MinLength(4), isText()]}
                  errorText="Texte uniquement"
                  initialValue={formState.inputs.adr_country.value}
                  initialValid={formState.inputs.adr_country.isValid}
                  onInput={inputHandler}
                />
                <Input
                  id="cvec"
                  name="cvec"
                  type="text"
                  typeOfInput="input"
                  label="Numéro d'attestation CVEC"
                  validators={[MinLength(4), isCvec()]}
                  errorText="Format LLLCLLLLLLCC (L = Lettre, C = chiffre) uniquement"
                  initialValue={formState.inputs.cvec.value}
                  initialValid={formState.inputs.cvec.isValid}
                  onInput={inputHandler}
                />
                {formState.inputs.cvec.value != "" && (
                  <FilePicker
                    mimeTypes=".jpg, .jpeg, .pdf"
                    inputHandler={inputHandler}
                    formState={formState}
                    formStateFileKey={"file"}
                    labelProps={
                      "Cliquez ici pour nous transmettre votre attestation de CVEC *"
                    }
                  />
                )}
                {/* Affichage du message d'erreur */}
                {errorCountryMessage && (
                  <p style={{ color: "red" }}>{errorCountryMessage}</p>
                )}
              </form>
              <div>
                <ActionBtn
                  id="submitForm"
                  btnType="contained"
                  activeBtnStyle={styles.btn_active}
                  btnText="Enregistrer"
                  textStyle={styles.btn_text}
                  onClick={saveDataHandler}
                />
              </div>
            </article>
          )}
          <ToastContainer />
        </main>
      </>
    );
};

export default ContactDetailsForm;
