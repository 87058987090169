/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { useForm } from "../../hooks/form-hook";
import { ToastContainer, toast } from 'react-toastify';

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";

// Styles
import styles from "./StudentEvalForm.module.scss";
import EvalPartOne from "../../components/StudentEvaluationForms/EvalPartOne";
import { StudentContext } from "../../context/student-context";
import { TuteurContext } from "../../context/tuteur-context";

const StudentEvalForm = () => {

    // Doc Type
    const docType = useParams().doc_type;
    
    // Authentication context
    const auth = useContext(AuthContext);

    const context = useContext(auth.userType == 'student' ? StudentContext : TuteurContext);

    // CSRF Token
    const [formJwt, setFormJwt] = useState();

    // Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    const studentLevelModalities = [
        {
            'b1' : '1ère année de Bachelor',
            'b2' : '2ème année de Bachelor',
            'b3' : '3ème année de Bachelor',
            'm1' : '1ère année de Mastère',
            'm2' : '2ème année de Mastère'
        },
    ];

    // Evaluation FormState
    const [formState, inputHandler, multipleInputHandler, setFormState] = useForm(
        {
            commitment_level: {
                value: auth.userType == 'student' ? studentLevelModalities[0][context.studentLevel] : studentLevelModalities[0][context.tuteurLevel],
                isValid: true,
            },
            commitment_caption: {
                value: auth.userType == 'student' ? context.studentCurrentPeriod : context.tuteurCurrentPeriod,
                isValid: true,
            },
            date_of_evaluation: {
                value: "",
                isValid: true,
            },
            tuteur_n_last: {
                value: "",
                isValid: false,
            },
            tuteur_n_first: {
                value: "",
                isValid: false,
            },
            tuteur_company: {
                value: "",
                isValid: false,
            },
            tuteur_position: {
                value: "",
                isValid: false,
            },
            tuteur_email: {
                value: "",
                isValid: false,
            },
            tuteur_tel_work: {
                value: "",
                isValid: false,
            },
            alternant_n_last: {
                value: "",
                isValid: false,
            },
            alternant_n_first: {
                value: "",
                isValid: false,
            },
            alternant_position: {
                value: "",
                isValid: false,
            },
            alternant_ecole: {
                value: "",
                isValid: false,
            },
            alternant_promo: {
                value: "",
                isValid: false,
            },
            type_contrat: {
                value: "",
                isValid: true,
            },
            "1_objectifs": {
                value: "",
                isValid: false,
            },
            "1_realisations": {
                value: "",
                isValid: false,
            },
            "1_difficultes": {
                value: "",
                isValid: true,
            },
            "1_evolutions": {
                value: "",
                isValid: true,
            },
            "1_bilan": {
                value: "",
                isValid: false,
            },
            "1_souhaits": {
                value: "",
                isValid: false,
            },
            "1_rqth_access": {
                value: "",
                isValid: true,
            },
            "1_rqth_amenagement": {
                value: "",
                isValid: true,
            },
            "1_rqth_delay": {
                value: "",
                isValid: true,
            },
            "2_atteinte_objectifs": {
                value: "",
                isValid: true,
            },
            "2_objectifs": {
                value: "",
                isValid: false,
            },
            "2_resultats": {
                value: "",
                isValid: true,
            },
            "2_evolutions": {
                value: "",
                isValid: false,
            },
            "2_bilan": {
                value: "",
                isValid: false,
            },
            "2_souhaits": {
                value: "",
                isValid: false,
            },
            "2_note_courtoisie": {
                value: "",
                isValid: false,
            },
            "2_note_autonomie": {
                value: "",
                isValid: false,
            },
            "2_note_implication": {
                value: "",
                isValid: false,
            },
            "2_note_adaptation": {
                value: "",
                isValid: false,
            },
            "2_note_force_proposition": {
                value: "",
                isValid: false,
            },
            "2_note_connaissances": {
                value: "",
                isValid: false,
            },
            "2_comportement_commentaire": {
                value: "",
                isValid: true,
            },
            "2_note_rigueur": {
                value: "",
                isValid: false,
            },
            "2_note_motivation": {
                value: "",
                isValid: false,
            },
            "2_note_qualite": {
                value: "",
                isValid: false,
            },
            "2_note_quantite": {
                value: "",
                isValid: false,
            },
            "2_note_ecoute": {
                value: "",
                isValid: false,
            },
            "2_note_disponibilite": {
                value: "",
                isValid: false,
            },
            "2_interet_commentaire": {
                value: "",
                isValid: true,
            },
            "2_note_bureautique": {
                value: "",
                isValid: false,
            },
            "2_note_outil_travail": {
                value: "",
                isValid: false,
            },
            "2_note_expertise_metier": {
                value: "",
                isValid: false,
            },
            "2_technique_commentaire": {
                value: "",
                isValid: true,
            },
            "2_ia_langage": {
                value: "",
                isValid: true,
            },
            "2_ia_commentaire": {
                value: "",
                isValid: true,
            },
            "2_note_organisation_travail": {
                value: "",
                isValid: false,
            },
            "2_note_respect_delai": {
                value: "",
                isValid: false,
            },
            "3_objectifs": {
                value: "",
                isValid: false,
            },
            "3_moyens": {
                value: "",
                isValid: false,
            },
            "3_souhait_stagiaire": {
                value: "",
                isValid: false,
            },
            "3_proposition_tuteur": {
                value: "",
                isValid: false,
            },
            "4_choix_stagiaire_1": {
                value: false,
                isValid: true,
            },
            "4_choix_stagiaire_2": {
                value: false,
                isValid: true,
            },
            "4_choix_stagiaire_3": {
                value: false,
                isValid: true,
            },
            "4_choix_stagiaire_4": {
                value: false,
                isValid: true,
            },
            "4_choix_stagiaire_5": {
                value: false,
                isValid: true,
            },
            "4_commentaire_stagiaire": {
                value: "",
                isValid: true,
            },
            "4_choix_tuteur_1": {
                value: false,
                isValid: true,
            },
            "4_choix_tuteur_2": {
                value: false,
                isValid: true,
            },
            "4_choix_tuteur_3": {
                value: false,
                isValid: true,
            },
            "4_choix_tuteur_4": {
                value: false,
                isValid: true,
            },
            "4_choix_tuteur_5": {
                value: false,
                isValid: true,
            },
            "4_choix_tuteur_6": {
                value: false,
                isValid: true,
            },
            "4_commentaire_tuteur": {
                value: "",
                isValid: true,
            },
        },
        false
    );

    // Evaluation FormState
    const [formContext, setFormContext] = useState();

    const structureData = async (doc) => {

        // New object to return to state
        let isValid = true;

        // Restructure server response
        for (const [key, value] of Object.entries(doc)) {
            
            // Split Key for data separation purposes
            const splitedKey = key.split("_");

            // Skip Invalid Data
            if (splitedKey.length <= 1) if (parseInt(splitedKey[0]) == key) continue;
            // Add Input data to formState
            formState.inputs[key] = { value: value || "", isValid: value ? true : false };
            
            if (key.substring(0, 7) === '4_choix') {
                formState.inputs[key] = { value: value || "", isValid: true };
            }


            // Change Form Validity
            if (!value) isValid = false;
        }

        // Return Constructed FormState
        return { stateStructure: formState.inputs };
        
    };

    // Fetch FormData
    useEffect(() => {
        const fetchEvaluation = async () => {
            try {
                // Fetch Document
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/evaluation-support/${docType}`;
                const { id, status, data, formJwt } = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                
                // Construct formState and set it
                const { stateStructure } = await structureData(data);
                setFormState(stateStructure);
                setFormJwt(formJwt);

                // Set Document Info
                setFormContext({ docId: Number(id), type: docType, status });

            } catch (err) {
                console.error(err);
            }
        };
        fetchEvaluation();
    }, []);

    return (
        <>
            <ToastContainer />
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && formState && formContext && (
                <main className={styles.container}>
                    <EvalPartOne
                        doctype={docType}
                        formState={formState}
                        inputHandler={inputHandler}
                        formContext={formContext}
                        formJwt={formJwt}
                    />
                </main>
            )}
        </>
    );
};

export default StudentEvalForm;
