import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { StudentContext } from "../../../../../context/student-context";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

// Components
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner";
import Avatar from "../../../../Avatar/Avatar";

// Images
import AvatarImg from "../../../../../assets/images/avatar.svg";

// Styles
import styles from "./SideCard.module.scss";

const SideCard = ({ student, setStudent, formJwt }) => {

    // Authentication context
    const auth = useContext(AuthContext);

    const history = useHistory();

    // Student Context
    const { studentProfilePhoto } = useContext(StudentContext);

    // Unique input file key to handle the value's reset in case of CSRF
    const [inputKey, setInputKey] = useState(Date.now())

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();

    // TabViews State
    const [imgSrc, setImgSrc] = useState(studentProfilePhoto !== "no-photo.png" ? `${process.env.REACT_APP_API_HOST}/profile/get-my-picture?token=${auth.token}` : AvatarImg);

    const rythme = {
        full_time: "Full Time",
        part_time: "Part Time",
        e_learning: "E-Learning",
    };

    const suivi_eleve = {
        initial: "Initial",
        searching: "En recherche",
        undefined: "?",
        company: "Professionnalisation",
        company_cpf: "Professionnalisation + CPF",
        apprenticeship: "Apprentissage",
        apprenticeship_cpf: "Apprentissage + CPF",
        training_course: "Stage",
        stage_monte: "Stage monté",
        training_course_cpf: "Stage + CPF",
        rupture_contrat: "Rupture de contrat",
        rupture_contrat_stage: "Rupture de contrat + Stage",
        student: "Job étudiant",
        service_civique: "Service civique",
        reclassement: "Reclassement",
        pole_emploi: "Pôle emploi",
        pole_emploi_cpf: "Pôle Emploi + CPF",
        pole_emploi_stage: "Pôle Emploi + Stage",
        transition_pro: "Transition Pro",
        transition_pro_cpf: "Transition Pro + CPF",
        transition_pro_stage: "Transition Pro + Stage",
        reclassement_stage: "Reclassement + Stage",
        reclassement_cpf: "Reclassement + CPF",
        aire: "AIRE",
        aire_stage: "AIRE + Stage",
        cpf: "CPF",
        fne: "FNE",
    };

    // Fonction qui va se charger de l'envois du fichier au serveur.
    const onImageChange = async (event) => {
        const formData = new FormData();

        // Creating a blob of the uploaded image to setting state with it. Requires the meta data "img-src * blob:" to works fine with CORS policy.
        const file = URL.createObjectURL(event.target.files[0]);

        formData.append("file", event.target.files[0]);

        try {
            const url = `${process.env.REACT_APP_API_HOST}/profile/profile-picture`;
            await sendRequest(url, "POST", formData, {
                Authorization: "Bearer " + auth.token,
                'X-CSRF-Token' : formJwt
            });

            if (file) {
              setImgSrc(file);
              setStudent((prevData) => {
                return { ...prevData, photo: file };
              });
            }
        } catch (err) {
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    console.log("test")
                    history.push("/profile");
                    setInputKey(Date.now());
                }, 3000)
            } else {
            toast.error(
                "Un problème est survenu sur le serveur, veuillez réessayer ultérieurement",
                {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                }
            );
            }
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000);
    }, [okHttp, error]);


    return (
        <div className={styles.profilDetailContainer}>
            <ToastContainer />
            <Avatar
                src={imgSrc}
                styles={styles.avatar}
                stylesAvatar={styles.styleAvatar}
            />
            <input
                type="file"
                id="file"
                key={inputKey}
                name="photo-upload"
                onChange={onImageChange}
                style={{ display: "none" }}
                className={styles.inputPhoto}
            />
            <input
                id="file"
                name="file"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={onImageChange}
            />
            {isLoading && (
                <div className="spinner">
                    <InLineLoadingSpinner textColor={styles.spinner_text_color} />
                </div>
            )}
            {!isLoading && okHttp && (
                <p style={{ color: "#00b61d" }}>Votre demande a bien été prise en compte. Merci.</p>
            )}
            {!isLoading && error && (
                <p style={{ color: "#e40000" }}>
                    Une erreur a été détectée, s'il vous plait, veuillez réessayer ultèrieurement.
                </p>
            )}
            <p className={styles.studentInfo}>{student.name}</p>
            <p className={styles.studentInfo}>{student.caption}</p>
            <p className={styles.studentInfo}>Groupe: {student.group_ids}</p>
            <p className={styles.studentInfo}>Rythme: {rythme[student.rythme]}</p>
            <p className={styles.studentInfo}>Suivi: {suivi_eleve[student.suivi_eleve]}</p>
        </div>
    );
};

export default SideCard;
