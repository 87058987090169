/* eslint-disable eqeqeq */
import React, {useContext, useEffect, useState} from "react";
import { useForm } from "../../../../../hooks/form-hook";
import { isRequired, isBlank } from "../../../../../utils/validators";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { AuthContext } from "../../../../../context/auth-context";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


// Components
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner"

// Styles
import styles from "./MyProfile.module.scss";

const MyProfil = ({ student, setStudent, departments, formJwt }) => {
    
    // Authentication context
    const auth = useContext(AuthContext);
    const history = useHistory();

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();
    const [validateSubmit, setValidateSubmit] = useState(false);

    // FormState Hook
    const [formState, inputHandler, multipleInputHandler] = useForm(
        {
            place_id: {
                value: student.place_id,
                isValid: student.place_id ? true : false,
            },
            n_last: {
                value: student.n_last,
                isValid: student.n_last ? true : false,
            },
            n_first: {
                value: student.n_first,
                isValid: student.n_first ? true : false,
            },
            int_1: {
                value: student.int_1 || "",
                isValid: student.int_1 ? true : false,
            },
            tinyint_3: {
                value: student.tinyint_3 || "",
                isValid: student.tinyint_3 ? true : false,
            },
            departments: {
                value: student.departments.split(',').map(num => parseInt(num, 10)) || "",
                isValid: true,
            },
            tinyint_5: {
                value: student.tinyint_5 || "",
                isValid: student.tinyint_5 ? true : false,
            },
        },
        false
    );

    const selectOptions = [
        { default: "", label: ""},
        { default: "Oui", label: "2" },
        { default: "Non", label: "1" },
    ];

    const driverOptions = [
        { default: "", label: ""},
        { default: "Permis de conduire + véhicule", label: "1" },
        { default: "Permis B ou autre", label: "2" },
        { default: "En cours", label: "3" },
        { default: "Non", label: "4" },
    ]

    const englishLevels = [
        { default: "", label: ""},
        { default: "A1", label: "1" },
        { default: "A2", label: "2" },
        { default: "B1", label: "3" },
        { default: "B2", label: "4" },
        { default: "C1", label: "5" },
        { default: "C2", label: "6" },
    ];

    const submit = async () => {
        const data = {
            int_1: formState.inputs.int_1.value,
            tinyint_3: formState.inputs.tinyint_3.value,
            departments: formState.inputs.departments.value,
            tinyint_5: formState.inputs.tinyint_5.value,
        };

        try {
            const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
            const response = await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                'X-CSRF-Token' : formJwt
            });

            setStudent((prevData) => {
                const { int_1, tinyint_3, departments, tinyint_5 } = data;
                return { ...prevData, int_1, tinyint_3, departments, tinyint_5 };
            });
            setValidateSubmit(true);
        } catch (err) {
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    history.push("/profile");
                }, 3000)
            } else {
                toast.error("Un problème est survenu sur le serveur, veuillez réessayer ultérieurement", {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000)
    }, [okHttp, error]);

    return (
        <>
            <ToastContainer />
            {isLoading && <InLineLoadingSpinner />}
            {okHttp && !isLoading && (
                <div className={styles.myProfilContainer}>
                    <p style={{ color: "#00b61d" }}>
                        Votre demande a bien été prise en compte. Merci.
                    </p>
                </div>
            )}
            {/* {error && !isLoading && (
                <div className={styles.myProfilContainer}>
                    <p style={{ color: "#e40000" }}>
                        Une erreur a été détectée, s'il vous plait, veuillez réessayer
                        ultèrieurement.
                    </p>
                </div>
            )} */}
            
            <div className={styles.myProfilContainer}>
                <div className={styles.profilInput}>
                    <Input
                        onInput={inputHandler}
                        label="Etablissement"
                        type="text"
                        name="place_id"
                        id="place_id"
                        typeOfInput="input"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.place_id.value}
                        initialValid={formState.inputs.place_id.isValid}
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Nom"
                        type="text"
                        name="n_last"
                        id="n_last"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.n_last.value}
                        initialValid={formState.inputs.n_last.isValid}
                        typeOfInput="input"
                        styles={styles.input}
                        disabled={true}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Prénom"
                        type="text"
                        name="n_first"
                        id="n_first"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.n_first.value}
                        initialValid={formState.inputs.n_first.isValid}
                        typeOfInput="input"
                        styles={styles.input}
                        disabled={true}
                    />
                </div>
                <div className={styles.profilInput}>
                    <Input
                        onInput={inputHandler}
                        label="Êtes vous en situation de handicap ?"
                        type="text"
                        name="int_1"
                        id="int_1"
                        typeOfInput="select"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.int_1.value}
                        initialValid={formState.inputs.int_1.isValid}
                        styles={styles.input}
                        content={selectOptions}
                        disabled={(formState.inputs.int_1.value != '' && validateSubmit) ? true : false}
                    />
                    <Input
                        onInput={inputHandler}
                        label="Permis :"
                        type="text"
                        name="tinyint_3"
                        id="tinyint_3"
                        typeOfInput="select"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.tinyint_3.value}
                        initialValid={formState.inputs.tinyint_3.isValid}
                        styles={styles.input}
                        content={driverOptions}
                        disabled={(formState.inputs.tinyint_3.value != '' && validateSubmit) ? true : false}
                    />
                      <Input
                        onInput={multipleInputHandler}
                        label="Mobilité pour l'alternance ou le stage :"
                        type="text"
                        name="departments"
                        id="departments"
                        typeOfInput="multiselect"
                        validators={[isBlank()]}
                        initialValue={formState.inputs.departments.value}
                        initialValid={formState.inputs.departments.isValid}
                        styles={styles.input}
                        content={departments}
                        disabled={(formState.inputs.departments.value != '' && validateSubmit) ? true : false}
                    />
                      <Input
                        onInput={inputHandler}
                        label="Niveau d'anglais :"
                        type="text"
                        name="tinyint_5"
                        id="tinyint_5"
                        typeOfInput="select"
                        validators={[isRequired()]}
                        initialValue={formState.inputs.tinyint_5.value}
                        initialValid={formState.inputs.tinyint_5.isValid}
                        styles={styles.input}
                        content={englishLevels}
                        disabled={(formState.inputs.tinyint_5.value != '' && validateSubmit) ? true : false}
                    />
                </div>
            </div>
            <div className={styles.savingBtnDiv}>
                <ActionBtn
                    id="subitCivilState"
                    btnType="contained"
                    btnStyle={styles.submitBtn}
                    activeBtnStyle={styles.btn_active}
                    btnText="ENREGISTRER"
                    textStyle={styles.btn_text}
                    onClick={submit}
                />
            </div>
        </>
    );
};

export default MyProfil;
