import React, { useState, useEffect, useContext } from "react";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import { AuthContext } from "../../context/auth-context";
import { useWindowDimensions } from "../../hooks/window-dimensions-hook";
import { structureConfigDocuments } from "../../utils/helperFunctions";
import { StudentContext } from "../../context/student-context";

// Components
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import HeaderBanner from "../../components/HeaderBanner/HeaderBanner";
import TabView from "../../components/TabView/TabView";
import DocumentListDisplay from "../../components/GenericTabs/DocumentsListDisplay/DocumentsListDisplay";

// Icons
import creIcon from "../../assets/images/relation_entreprise.svg";
import downloadIcon from "../../assets/images/download-solid.svg";
import editIcon from "../../assets/images/edit-solid.svg";

// Styles
import styles from "./Careers.module.scss";

const Careers = () => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Student context
    const studentContext = useContext(StudentContext);

    // Backend Request Hook
    const { isLoading, error, sendRequest } = useHttpRequest();

    const [tabViews, SetTabViews] = useState();

    const { width } = useWindowDimensions();

    const notificationLocal = localStorage.getItem("studentNotificationAlert");

    const [formJwt, setFormJwt] = useState();


    const [careersNav] = useState([
        {
            id: "mygema_carriere",
            btnText: "Carrières",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
        {
            id: "mygema_relation_entreprise",
            btnText: "Relations Entreprises",
            btnType: "text",
        },
        {
            id: "mygema_offre",
            btnText: "Offres",
            btnType: "text",
            disabled: (auth.userType === "tuteur" || studentContext.studentOffresRestriction == true) ? true : false,
            notificationAlert: studentContext.studentOffresRestriction ? false : notificationLocal,
        },
        {
            id: "mygema_evenements",
            btnText: "Événements",
            btnType: "text",
            disabled: auth.userType === "tuteur" ? true : false
        },
    ]);

    // Fetch Documents
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const url = `${process.env.REACT_APP_API_STUDENT}/documents/config/careers`;
                const documents = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                setFormJwt(documents.formJwt);

                // We sliced the formJwt from the documents object to avoid errors.
                const { formJwt, ...documentSliced } = documents;
                // Structure Data
                const structuredDocuments = await structureConfigDocuments(documentSliced, editIcon, downloadIcon, styles.download_icon_color);

                // Create View Tabs
                let tabViewArray = [];
                Object.entries(structuredDocuments).forEach(([key, value]) =>
                    tabViewArray.push({
                        id: key,
                        view: <DocumentListDisplay id={key} content={value} formJwt={formJwt} />,
                    })
                );

                // Set States
                SetTabViews(tabViewArray);
            } catch (err) {
                console.error(err);
            }
        };
        fetchDocuments();
    }, []);


    return (
        <>
            {isLoading && (
                <div className="spinner">
                    <LoadingSpinner />
                </div>
            )}
            {error && (
                <div className="error_wrapper">
                    <HttpMessagePrompt error={error} />
                </div>
            )}
            {!isLoading && careersNav && tabViews && (
                <main className={styles.container}>
                    <HeaderBanner
                        title={width <= 568 ? "Carrières & RE" : "Carrières & Relations entreprises"}
                        icon={creIcon}
                        iconColor={styles.icon_color}
                        bgColor={styles.banner_color}
                    />
                    <TabView
                        menuConfig={careersNav}
                        tabViews={tabViews}
                        activeBtnColor={styles.btn_active}
                    />
                </main>
            )}
        </>
    );
};

export default Careers;