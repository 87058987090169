/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { useForm } from "../../../../../hooks/form-hook";
import { isRequired } from "../../../../../utils/validators";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

// Components
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner";
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";

// Styles
import styles from "./CivilState.module.scss";

const CivilState = ({ student, setStudent, formJwt }) => {

    // Authentication context
    const auth = useContext(AuthContext);

    const history = useHistory();

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();

    // FormState Hook
    const [formState, inputHandler, multipleInputHandler] = useForm(
        {
            birth_date: {
                value: student.birth_date,
                isValid: student.birth_date ? true : false,
            },
            place_of_birth: {
                value: student.place_of_birth,
                isValid: student.place_of_birth ? true : false,
            },
        },
        false
    );

    const submit = async () => {
        
        const data = {
            birth_date: formState.inputs.birth_date.value,
            place_of_birth: formState.inputs.place_of_birth.value
        }

        const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
        try {
                await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                'X-CSRF-Token' : formJwt
            });

            setStudent((prevData) => {
                const { birth_date, place_of_birth } = data;
                return { ...prevData, birth_date, place_of_birth };
            });
        } catch (err) {
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    history.push("/profile");
                }, 3000)
            } else {
                toast.error("Un problème est survenu sur le serveur, veuillez réessayer ultérieurement", {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000);
    }, [okHttp, error]);

    return (
        <div className={styles.civilStateInput}>
            <ToastContainer />
            {okHttp && !isLoading && (
                <p style={{ color: "#00b61d", textAlign: "center" }}>
                    Votre demande a bien été prise en compte. Merci.
                </p>
            )}
            <Input
                id="birth_date"
                name="birth_date"
                type="date"
                typeOfInput="input"
                pattern="\d{4}-\d{2}-\d{2}"
                label="Date de naissance *"
                placeholder="jj/mm/aaaa"
                validators={[isRequired()]}
                errorText="Champ requis."
                initialValue={formState.inputs.birth_date.value}
                initialValid={formState.inputs.birth_date.isValid}
                onInput={inputHandler}
                styles={styles.input}
            />
            <Input
                id="place_of_birth"
                name="place_of_birth"
                type="text"
                typeOfInput="input"
                label="Lieu de naissance *"
                placeholder="Nantes"
                validators={[isRequired()]}
                errorText="Champ requis."
                initialValue={formState.inputs.place_of_birth.value}
                initialValid={formState.inputs.place_of_birth.isValid}
                onInput={inputHandler}
                styles={styles.input}
            />            
            <ActionBtn
                id="subitCivilState"
                btnType="contained"
                btnStyle={styles.submitBtn}
                activeBtnStyle={styles.btn_active}
                btnText="ENREGISTRER"
                textStyle={styles.btn_text}
                onClick={submit}
            />
            {isLoading && <InLineLoadingSpinner />}
            <ToastContainer />
        </div>
    );
};

export default CivilState;
